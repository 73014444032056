<template>
	<v-row>
		<v-col>
			<v-row>
				<v-col cols="6" class="d-flex pr-0 align-end">
					<div>
						<div class="petu-green headline font-weight-bold text-left">Kenialyz Martínez</div>
						<div class="petu-pink text-left mb-5">Creadora del movimiento 'Petu Power'</div>
						<!-- <div class="text-caption text-left grey--text pr-7">Te ayudo a crear una confianza en tí misma que nadie puede romper</div> -->
					</div>
				</v-col>
				<v-col cols="6" class="pr-0 pb-0">
					<v-img class="rounded-l-xl" height="250" src="@/assets/images/IMG_1566.jpeg" position="top center"></v-img>
				</v-col>
				<v-col cols="6" offset="6" class="pt-0 pr-0 text-left petu-green">
					<div class="text-caption">Personal trainer · Petu trainer</div>
				</v-col>
			</v-row>
			<v-row class="justify-center py-7">
				<v-col cols="12" class="">
					<h3 class="petu-green mb-3">¿Quiénes somos?</h3>
					<!-- <div class="grey--text text-caption">El movimiento Petu Power nace de la visión de su propietaria, la entrenadora personal Kenializ Martínez. El Petu Gym reúne a un grupo de mujeres poderosas, que teniendo el ejercicio como base, se van convirtiendo en su mejor versión. Fortaleciendo su amor propio y desarrollando confianza en sí mismas.</div> -->
					<div class="grey--text text-caption">
						<p>Petu Power se enorgullece de contar con un equipo de entrenadores altamente cualificados y dedicados, quienes están comprometidos en brindar un ambiente profesional y de apoyo a nuestra valiosa petucomunidad.</p>
						<p>Nuestro enfoque va más allá de la transformación física, ya que también nos preocupamos por el bienestar mental y emocional de cada individuo. Creemos firmemente que esta es la clave para lograr una transformación sostenible y duradera a largo plazo, sin experimentar ansiedad, recaída o sufrimiento.</p>
						<p>En Petu Power, nos esforzamos para crear un ambiente positivo y colaborativo, donde todos se sientan motivados y respaldados en su camino hacia una vida más saludable. Confía en nuestro equipo de expertos para brindarte el apoyo necesario y ayudarte a alcanzar tus metas de manera profesional y exitosa.</p>
					</div>
				</v-col>
			</v-row>

			<v-row class="justify-center pb-7 pt-0">
				<v-col cols="12" class="">
					<h3 class="petu-green mb-3">Nuestro equipo</h3>
					<div class="d-flex h-scroll">
						<template v-for="(emp, ix) in staff">
							<v-card flat width="150" class="mr-2" :key="ix" @click="openBioDialog(ix)">
								<v-img :src="`${emp.gallery[0]}`" class="rounded" />
								<v-card-text class="px-0 pb-0" style="height: 85px">
									<h3 class="mt-0">{{ emp.name }}</h3>
									<div class="text-caption grey--text">{{ emp.title }}</div>
								</v-card-text>
								<v-card-actions>
									<v-btn small text depressed class="rounded-pill petu-pink-bg white--text no-uppercase mt-3">Ver bio</v-btn>
								</v-card-actions>
							</v-card>
						</template>
					</div>
				</v-col>
			</v-row>

			<v-row class="petu-orange-bg justify-center py-7">
				<v-col cols="12" class="white--text">
					<h3 class="mb-3">Servicios que ofrecemos</h3>
					<div class="text-caption">Se ofrecen diferentes tipos de entrenamiento para tu comodidad. Decide cuál es el perfecto para ti y lee las reglas que debes seguir.</div>
				</v-col>

				<v-col cols="7" class="pb-1">
					<v-btn to="/productos/presencial" block class="rounded-pill petu-pink-bg white--text no-uppercase">Entrenamiento presencial</v-btn>
				</v-col>
				<v-col cols="7" class="pb-1">
					<v-btn to="/productos/online" block class="rounded-pill petu-pink-bg white--text no-uppercase">Entrenamiento en línea</v-btn>
				</v-col>
				<!-- <v-col cols="7" class="pb-1">
					<v-btn block class="rounded-pill petu-pink-bg white--text no-uppercase" @click="oneOnOneDialog = true">One on One</v-btn>
				</v-col> -->
				<v-col cols="7" class="pb-1">
					<v-btn block class="rounded-pill petu-pink-bg white--text no-uppercase">Petu Outfit (pronto)</v-btn> <!-- to="/productos/outfit" -->
				</v-col>
				<!-- <v-col cols="7" class="pb-1">
					<v-btn to="/productos/accesories" block class="rounded-pill petu-pink-bg white--text no-uppercase">Petu Accesorios</v-btn>
				</v-col> -->
			</v-row>
			<v-row class="petu-green-bg justify-center py-7">
				<v-col cols="12" class="white--text">
					<h3 class="mb-3">Petu cambios</h3>
					
					<v-carousel hide-delimiters hide-delimiter-background cycle height="400">

						<v-carousel-item>
							<v-img src="@/assets/petu-cambio1.jpeg" position="center center" height="100%" contain></v-img>
						</v-carousel-item>
						<v-carousel-item>
							<v-img src="@/assets/petu-cambio2.jpeg" position="center center" height="100%" contain></v-img>
						</v-carousel-item>
						<v-carousel-item>
							<v-img src="@/assets/petu-cambio3.jpeg" position="center center" height="100%" contain></v-img>
						</v-carousel-item>
						<v-carousel-item>
							<v-img src="@/assets/petu-cambio4.jpeg" position="center center" height="100%" contain></v-img>
						</v-carousel-item>
						<v-carousel-item>
							<v-img src="@/assets/petu-cambio5.jpeg" position="center center" height="100%" contain></v-img>
						</v-carousel-item>
						<v-carousel-item>
							<v-sheet height="100%" tile>
								<v-img src="@/assets/petu-cambio6.jpeg" position="center center"></v-img>
							</v-sheet>
						</v-carousel-item>

					</v-carousel>

					<!-- <v-img class="rounded-xl" height="250" src="@/assets/petu-team.jpg" position="center center"></v-img> -->
				</v-col>
			</v-row>

			<v-row class="justify-center py-7">
				<v-col cols="12" class="">
					<h3 class="petu-green mb-3">Eventos y actividades</h3>
					<v-img :src="require('@/assets/images/calendario_marzo_2024.jpg')" @click="calendarDialog = true" />
				</v-col>
			</v-row>

			<v-row class="justify-center py-7 pb-0">
				<v-col cols="12" class="">
					<h3 class="petu-green mb-3 text-center">¡Únete hoy al team de las Petu Girls!</h3>
					<!-- <div class="grey--text text-caption">Elige el entrenamiento de tu preferencia y deja que el Petu Power se apodere de tu cuerpo</div> -->
				</v-col>

				<!-- <v-col cols="7" class="pb-1">
					<v-btn block class="rounded-pill petu-orange-bg white--text no-uppercase" to="/productos">Más información</v-btn>
				</v-col> -->
			</v-row>
			<!-- <v-row class="petu-green-bg justify-center py-7">
				<v-col cols="12" class="white--text">
					<h3 class="mb-3">Equipo</h3>
					<div class="text-caption pb-2">Este es el equipo de Petu Power. Mujeres poderosas e independientes listas para ayudarte a ti.</div>
					<div class="text-caption pb-2"><strong>Kenialyz</strong> es la entrenadora y dueña de Petu Power, persona principal de esta familia.
					<strong>Lianys</strong> es la petu administradora, encargada de hacer todas las actividades y preparar los mejores mega té.
					<strong>Mile</strong>, es la petu asistente, la que aclara cualquier duda que tengas, encargada de la parte administrativa.</div>
					<div class="text-caption pb-2">Aquí te esperamos con los brazos abiertos para que seas una petugirl. 🍑💛</div>

					<v-img class="rounded-xl" height="250" src="@/assets/petu-team.jpg" position="center center"></v-img>
				</v-col>
			</v-row> -->
		</v-col>
		<v-dialog v-model="oneOnOneDialog">
			<v-card>
				<v-card-title>Entrenamiento One on One</v-card-title>
				<v-card-text>
					Para este tipo de entrenamiento debes comunicarte directamente al petu phone, <a href="tel:+19394896563">(939) 489-6563</a> para coordinar agenda con la petu trainer.
				</v-card-text>
				<v-card-actions>
					<v-btn depressed block color="primary" @click="oneOnOneDialog = false">Ok</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="bioDialog">
			<v-card v-if="emp != null">
				<v-img :src="emp.gallery[0]" position="top center"></v-img>
				<v-card-title>{{ emp.name }}</v-card-title>
				<v-card-subtitle>{{ emp.title }}</v-card-subtitle>

    			<v-card-text>
					<div v-html="emp.bio"></div>
					<div style="color:#499937;" v-if="emp.schedule.length > 0">
						Disponible {{ emp.schedule }}
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="calendarDialog">
			<v-card>
				<v-card-title>Eventos y actividades</v-card-title>
				<v-card-subtitle>Este calendario está sujeto a cambios. Cualquier alteración al mismo será notificado por el Petuchat.</v-card-subtitle>
				<v-card-text>
					<v-list dense>
						<template v-for="(e, ix) in events">
							<v-list-item class="px-0" :key="ix">
								<v-list-item-avatar tile class="rounded">
									<div class="white--text pa-2" style="background-color: rgb(238, 58, 140)">
										<div>{{ e.month }}</div>
										<div>{{ e.day }}</div>
									</div>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title>{{ e.name }}</v-list-item-title>
									<v-list-item-subtitle>{{ e.time.length > 0 ? e.time : '' }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-list>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-row>
</template>
<script>
export default { 
	name: 'Home',
	data() {
		return {
			oneOnOneDialog: false,
			bioDialog	: false,
			calendarDialog: false,
			emp			: null,

			staff	: [
				{ 
					name: 'Kenia Martínez', 
					title: 'Entrenadora personal y creadora del movimiento PetuPower', 
					bio: '<p>Mi nombre es Kenialyz Martinez, entrenadora personal certificada hace ya 3 años y decidida a crear una comunidad de mujeres que se preocupe por su salud física y mental. He creado una comunidad de PetuGirls con el fin de quitar ese ideal de compararnos con otras mujeres, quitar ya la competencia y ser una comunidad de mujeres que nos unimos unas con otras para crecer, ayudarnos y crecer juntas en este camino difícil de mejorar física y mentalmente. Que entiendan que por lo que estas pasando hay otras mujeres pasando por lo mismo. En este proyecto he decidido atender solo mujeres para que ellas se sientan completamente cómodas, que se sientan que no están solas y que somos unas mujeres poderosas que podemos cambiar el mundo si tan solo nos uniéramos. Fortaleciendo su amor propio y desarrollando confianza en si mismas.</p><p>Les quiero enseñar que somos capaces de tanto, que somos fuertes y que podemos con todo lo que la vida nos tira.</p>', 
					schedule: '',
					gallery: [
						require('@/assets/images/IMG_1578_cropped.jpeg'),
					] 
				},
				{ 
					name: 'Xavier Rivera', 
					title: 'Entrenador personal', 
					bio: '<p>Mi nombre es Xavier Rivera Colón tengo 41 años y soy entrenador personal desde el 2023. Decidí ser entrenador porque además, de que me apasiona impactar de manera positiva la vida de los demás, el entrenar ha mejorado mi calidad de vida.  Para mi ser entrenador va más allá de alcanzar una meta física se trata de llevarte a tu máximo nivel aumentando principalmente la confianza en ti mismo.</p>', 
					schedule: '',
					gallery: [
						require('@/assets/images/xavier.jpeg'),
						require('@/assets/images/kenia_xavier.jpeg')
					] 
				},
				{ 
					name: 'Naomi Curbelo', 
					title: 'Instructora de pole dance, ballet, twerk y floorwork', 
					bio: '<p>Instructora de pole dance,ballet ,Twerk y floorwork. Lleva trece años en la industria del baile, diez de ellos en la práctica del ballet clásico bajo la tutela de Wayne Riport y Carla Curret. Certificada en ballet y bailes contemporáneos. Ganadora de medalla de oro en Pole up tournament 2022 en Buenos Aires,  Argentina. Integrante del colectivo Las libertas el cuál se ha destacado por sus consistentes eventos en donde se desplaza el arte del pole dance.</p>', 
					schedule: '2 lunes al mes',
					gallery: [
						require('@/assets/images/naomi.jpg')
					] 
				},
				{ 
					name: 'Ana Castillo', 
					title: 'Periodista, escritora y coach sexual', 
					bio: '<p>Ana Castillo Muñoz es periodista, escritora, coach sexual, creadora del proyecto Con el verbo en la piel y autora de Corona de Flores (Editorial EDP University, 2021).</p>', 
					schedule: '2 viernes al mes en el Petugym',
					gallery: [
						require('@/assets/images/ana_castillo.jpg')
					] 
				},
				{ 
					name: 'Rose Nieves', 
					title: 'Taróloga, astróloga y coach espiritual', 
					bio: '<p>Rose Nieves es una destacada profesional en el ámbito de las artes místicas, con amplia experiencia como taróloga, astróloga y coach espiritual. Con siete años de dedicación al Tarot con enfoque evolutivo y espiritual, ha brindado orientación y apoyo a numerosas personas en sus procesos personales.</p><p>Su compromiso y habilidad para acompañar a otros a través de las artes místicas la han posicionado como una guía confiable y respetada en el camino espiritual de aquellos que buscan claridad y entendimiento en sus vidas.</p>', 
					schedule: '2 sábados al mes en el Petugym',
					gallery: [
						require('@/assets/images/rose_2.png'),
						require('@/assets/images/rose_1.jpg')
					] 
				},
				{
					name: 'Aneek Uhuru',
					title: 'Maestra de yoga',
					bio: '<p>Aneek Uhuru es Maestra de Yoga Certificada (RYT-500), Coach Holístico, Reiki Master y artista interdisciplinaria. Cuenta con un bachillerato en Psicología de la Universidad de Puerto Rico, Recinto de Río Piedras. Se dedica a investigar y facilitar prácticas de salud holística, trabajando principalmente con técnicas de Yoga y danza-movimiento terapia.</p>',
					schedule: '2 viernes al mes en el Petugym',
					gallery: [
						require('@/assets/images/aneek.jpg')
					]
				}
			],

			events: [
				{ month: 'Mar', day: '04', time: '', name: 'Comienzo de semana' },
				{ month: 'Mar', day: '04', time: '8:00 pm', name: 'Practica Softball' },
				{ month: 'Mar', day: '05', time: '', name: 'Entrenador Xavier' },
				{ month: 'Mar', day: '08', time: '', name: 'Entrenadora Isha' },
				{ month: 'Mar', day: '08', time: '6:00 pm', name: 'PetuYoga' },
				{ month: 'Mar', day: '10', time: '12:00 pm', name: 'Dia petufamiliar' },
				{ month: 'Mar', day: '11', time: '6:00 pm', name: 'Clase twerk con Trendy' },
				{ month: 'Mar', day: '11', time: '8:00 pm', name: 'Practica Softball' },
				{ month: 'Mar', day: '12', time: '', name: 'Entrenador Xavier' },
				{ month: 'Mar', day: '12', time: '', name: 'Comienzo de semana' },
				{ month: 'Mar', day: '13', time: '', name: 'Masajes Tiffany' },
				{ month: 'Mar', day: '15', time: '', name: 'Entrenadora Isha' },
				{ month: 'Mar', day: '15', time: '6:00 pm', name: 'PetuYoga' },
				{ month: 'Mar', day: '16', time: '7:00 am', name: 'PetuMerengazo' },
				{ month: 'Mar', day: '16', time: '8:00 am', name: 'PetuMeditación' },
				{ month: 'Mar', day: '18', time: '6:00 pm', name: 'Clase salsa oreo' },
				{ month: 'Mar', day: '18', time: '8:00 pm', name: 'Practica Softball' },
				{ month: 'Mar', day: '19', time: '', name: 'Entrenador Xavier' },
				{ month: 'Mar', day: '19', time: '', name: 'Comienzo de semana' },
				{ month: 'Mar', day: '22', time: '', name: 'Entrenadora Isha' },
				{ month: 'Mar', day: '22', time: '6:00 pm', name: 'PetuYoga' },
				{ month: 'Mar', day: '22', time: '6:00 pm', name: 'Taller Meteletech' },
				{ month: 'Mar', day: '25', time: '', name: 'Comienzo de semana' },
				{ month: 'Mar', day: '25', time: '8:00 pm', name: 'Practica Softball' },
				{ month: 'Mar', day: '26', time: '', name: 'Emtrenador Xavier' },
				{ month: 'Mar', day: '27', time: '', name: 'Masajes Tiffany' },
				{ month: 'Mar', day: '27', time: '6:00 pm', name: 'Taller con Verbo y Piel' },
				{ month: 'Mar', day: '29', time: '', name: 'Entrenadora Isha' },
				{ month: 'Mar', day: '30', time: '8:00 am', name: 'PetuMeditación' },
			]
		}
	},
	methods: {
		openBioDialog( ix ) {
			this.emp = this.staff[ix];
			this.bioDialog = true;
		}
	}
}
</script>

<style scoped>
.h-scroll {
	overflow-y: auto;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.h-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.h-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 
</style>
